import { Component } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

@Component({
    selector: 'app-manuals',
    templateUrl: './manuals.component.html',
    styleUrl: './manuals.component.scss'
})
export class ManualsComponent {
    manuals: { text: string; link: string }[] = [
        {
            text: _('VBS manual'),
            link: _('LinkManualsGeneralInstructionsVBS')
        },
        {
            text: _('Information for truckers'),
            link: _('LinkManualsInformationForTruckers')
        },
        {
            text: _('Quick reference'),
            link: _('LinkManualsQuickReference')
        },
        {
            text: _(
                'Instructions for organization administrator (including adding fleet/plate number - last page of instructions)'
            ),
            link: _('LinkManualsInstructionForOrganizationAdministrator')
        },
        {
            text: _("Video instruction for adding a truck plate to the organization's fleet list"),
            link: _('LinkManualsVideoAddTrackToFleet')
        },
        {
            text: _("Video instruction for adding a trailer plate to the organization's fleet list"),
            link: _('LinkManualsVideoAddTrailerToFleet')
        }
    ];
}
