import { ChangeDetectorRef, Component } from '@angular/core';
import { NotificationService } from '@common/services/notification.service';

@Component({
    selector: 'app-notification-bar',
    template: `
        @if (isVisible) {
            <div class="animation bg-info">
                <span class="inline overflow-hidden text-ellipsis whitespace-nowrap">{{ notificationMessage }}</span>
            </div>
        }
    `,
    styles: [
        `
            .animation {
                line-height: 30px;
                color: white;
                white-space: nowrap;
                overflow: hidden;
                box-sizing: border-box;
                font-weight: bold;
            }

            .animation span {
                display: inline-block;
                padding-left: 100%;
                animation: animation 30s linear infinite;
            }

            @keyframes animation {
                0% {
                    transform: translateX(0%);
                }

                100% {
                    transform: translateX(-100%);
                }
            }
        `
    ],
    standalone: true
})
export class NotificationBarComponent {
    isVisible: boolean = false;
    notificationMessage: string = '';

    constructor(
        private cdr: ChangeDetectorRef,
        private notificationService: NotificationService
    ) {
        this.notificationService.getNotifications().subscribe((notification) => {
            this.notificationMessage = notification;
            if (this.notificationMessage) this.isVisible = true;
            else this.isVisible = false;
            this.cdr.markForCheck();
        });
    }
}
