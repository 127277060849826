import { Component, ViewEncapsulation } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { FormProperty } from '@common/components/app-control/app-control.component';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { ViewMode } from '@common/models/view-mode';
import { CommonService } from '@common/services/common.service';
import { NotificationAlert } from '../notification-alert.interface';

@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
    providers: [CommonService],
    encapsulation: ViewEncapsulation.None
})
export class NotificationListComponent extends BaseListComponent {
    override columns: ColumnSettings[];
    queryName = 'Notifications';

    constructor(commonService: CommonService) {
        super(commonService);
    }

    get filterProperties(): FormProperty[] {
        return [
            {
                name: 'id',
                label: this.common.translateService.instant('Id'),
                type: 'string',
                colSpan: 2,
                initialValue: this.filter?.id
            },
            {
                name: 'content',
                label: this.common.translateService.instant('Content'),
                type: 'string',
                colSpan: 3,
                initialValue: this.filter?.content
            },
            {
                name: 'validFromDate',
                label: this.common.translateService.instant('From Date'),
                type: 'datetime',
                colSpan: 3,
                initialValue: this.filter?.validFromDate
            },
            {
                name: 'validUntilDate',
                label: this.common.translateService.instant('To Date'),
                type: 'datetime',
                colSpan: 3,
                initialValue: this.filter?.validUntilDate
            },
            {
                name: 'alert',
                label: this.common.translateService.instant('Alert'),
                type: 'boolean',
                colSpan: 2,
                initialValue: this.filter?.alert,
                nullable: true
            }
        ];
    }

    alarmOnOff(row: NotificationAlert) {
        row.alert = !row.alert;
        this.common.queryService
            .getCommandMutation()
            .mutateAsync({
                command: 'SaveNotification',
                data: { saveModel: row, id: row?.id, action: ViewMode.edit },
                invalidate: 'notificationAlert'
            })
            .then(() => {
                this.common.queryService.queryClient.invalidateQueries({ queryKey: ['ActiveNotifications'] });
                this.common.toastrNotificationService.show({
                    type: 'success',
                    message: this.common.translateService.instant('Alert notification status changed.')
                });
            })
            .catch((err) => {
                this.toastrNotificationService.show({
                    type: 'error',
                    message: this.common.translateService.instant('Failed to change notification status!')
                });
                return err;
            });
    }
}
