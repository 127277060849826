import { CodeList } from '@common/classes/codelist';

/**
 * Formats a codelist item to display as "CODE - NAME"
 * @param item The codelist item to format
 * @returns Formatted string in "CODE - NAME" format
 */
export const formatCodelistLabelCodeDashName = (item: CodeList): string => {
    return `${item.code} - ${item.customText || item.name}`;
};
