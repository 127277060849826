import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { ViewMode } from '@common/models/view-mode';
import { FaqModule } from 'app/faq/faq.module';
import { ManualsComponent } from './components/manuals/manuals.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NotificationViewComponent } from './components/notification-view/notification-view.component';

export const INFO_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'notification/list',
        pathMatch: 'full'
    },
    {
        path: 'notification/list',
        component: NotificationListComponent
    },
    {
        path: 'notification/edit/:id',
        component: NotificationViewComponent,
        data: {
            mode: ViewMode.edit
        }
    },
    {
        path: 'notification/view/:id',
        component: NotificationViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'notification/create',
        component: NotificationViewComponent,
        data: {
            mode: ViewMode.create
        }
    },
    {
        path: 'manuals',
        component: ManualsComponent
    },
    {
        path: 'faq',
        loadChildren: () => FaqModule,
        data: { title: 'FAQ' }
    },
    {
        path: '**',
        redirectTo: 'notification/list'
    }
];

@NgModule({
    declarations: [NotificationListComponent, NotificationViewComponent, ManualsComponent],
    imports: [CommonModule, RouterModule.forChild(INFO_ROUTES)]
})
export class InfoModule {}
