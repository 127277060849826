import { Injectable } from '@angular/core';
import { RememberStateService } from './remember-state.service';

@Injectable({
    providedIn: 'root'
})
export class ZktLoginService {
    private isActivated: boolean;

    constructor(private rememberStateService: RememberStateService) {
        // Initialize the dark mode state from the remember state service
        this.isActivated = this.rememberStateService.get('zktLoginFlag') ?? false;
    }

    set(isEnabled: boolean): void {
        this.isActivated = isEnabled;
        // Save the state in remember state service
        this.rememberStateService.set('zktLoginFlag', isEnabled);
    }

    toggle(): void {
        this.set(!this.isActivated);
    }

    get(): boolean {
        return this.isActivated;
    }
}
