import { Component, Input } from '@angular/core';
import { GridCellContext, GridTemplateComponent } from '@common/interfaces/column-settings.interface';
import { NgIcon } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';

export interface EIRInputs {
    eir: number;
    action: () => void;
}

@Component({
    standalone: true,
    imports: [NgIcon, TranslateModule],
    template: `
        @if (eir) {
            <ng-icon
                name="faSolidPrint"
                [class.cursor-pointer]="!!eir"
                [class.hidden]="!eir"
                [title]="'Print EIR' | translate"
                (click)="action()"></ng-icon>
        }
    `
})
export class EIRCellComponent implements GridTemplateComponent, EIRInputs {
    @Input() context!: GridCellContext;
    @Input() eir!: number;
    @Input() action!: () => void;
}
