import { Component, Input } from '@angular/core';
import { CommonModule } from '@common/common.module';
import { GridCellContext, GridTemplateComponent } from '@common/interfaces/column-settings.interface';

export interface CodeListDashNameInputs {
    codelistName: string;
    code: string;
}

@Component({
    standalone: true,
    imports: [CommonModule],
    template: `
        <span>{{ code | codelist: codelistName : true | async }}</span>
    `
})
export class CellCodeListDashNameComponent implements GridTemplateComponent, CodeListDashNameInputs {
    @Input() context!: GridCellContext;
    @Input() codelistName!: string;
    @Input() code!: string;
}
