import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { IconKey } from '@common/classes/icons';
import { NoticeBoardPermissions } from '@common/classes/permissions';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'app-notification-view',
    templateUrl: './notification-view.component.html',
    providers: [CommonService],
    encapsulation: ViewEncapsulation.None
})
export class NotificationViewComponent extends BaseViewComponent implements OnInit {
    override editPermission = NoticeBoardPermissions.Action.Edit;
    override createPermission = NoticeBoardPermissions.Action.Create;
    entityName = 'Notification';
    icon: IconKey = 'faSolidFile';

    constructor(common: CommonService) {
        super(common);
    }

    override afterSave(model: any): Promise<void> {
        this.common.queryService.queryClient.invalidateQueries({ queryKey: ['ActiveNotifications'] });
        return super.afterSave(model);
    }
}
