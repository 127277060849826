import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ZktLoginService } from '@common/services/zkt-login.service';

export const ZKT_LOGIN_KEY = 'zktLoginFlag';

export const zktOnlyLoginGuard: CanActivateFn = (route, state) => {
    const zktService = inject(ZktLoginService);
    zktService.set(true);
    return true;
};
