<app-content-header
    title="{{ 'Notifications' | translate }}"
    icon="faSolidBell"
    [actionBar]="actionBar"></app-content-header>
<app-active-filters [listOfActiveFilters]="listOfActiveFilters" (clearFilter)="clearFilter($event)" />
<app-drawer [toggle]="filterOpen" (search)="search()" (clearFilter)="clearFilter()">
    <app-control label="Name" [(ngModel)]="filter.name" />
</app-drawer>
<app-grid
    [data]="query()"
    [nrOfActiveFilters]="nrOfActiveFilters"
    [parentRoute]="parentRoute"
    [defaultViewMode]="defaultViewMode"
    [queryData]="search"
    [autoFitColumns]="false"
    (saveGridSettings)="saveGridSettings($event)"
    (deleteGridSettings)="deleteGridSettings()"
    (toggleFilter)="filterDialog(filterProperties)"
    (clearFilter)="clearFilter($event)">
    <kendo-grid-column class="w-1" headerClass="w-1" title="{{ 'Id' | translate }}">
        <ng-template kendoGridCellTemplate let-row>
            <app-link [route]="['/info/notification', defaultViewMode, row.id]">
                {{ row.id }}
            </app-link>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        class="w-20"
        headerClass="w-20"
        title="{{ 'Message' | translate }}"
        field="content"></kendo-grid-column>
    <kendo-grid-column class="w-8" type="datetime" headerClass="w-8" title="{{ 'From Date' | translate }}">
        <ng-template kendoGridCellTemplate let-row>
            <span>{{ row.validFromDate | date: 'dd.MM.yyyy HH:mm' }}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="w-8" type="datetime" headerClass="w-8" title="{{ 'To Date' | translate }}">
        <ng-template kendoGridCellTemplate let-row>
            <span>{{ row.validUntilDate | date: 'dd.MM.yyyy HH:mm' }}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="w-1" headerClass="w-1" title="{{ 'Alert' | translate }}">
        <ng-template kendoGridCellTemplate let-row>
            <app-control type="boolean" [ngModel]="row.alert" (click)="alarmOnOff(row)"></app-control>
        </ng-template>
    </kendo-grid-column>
</app-grid>
