<app-content-header title="{{ 'Manuals' | translate }}" icon="faSolidBook"></app-content-header>
<kendo-listview class="h-full rounded-md border-0 bg-card p-2 shadow-md" [data]="manuals">
    <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
        <a
            class="my-2 block rounded-md bg-popover px-4 py-3 text-start font-bold text-popover-foreground transition-colors duration-300 hover:bg-popover-foreground/5"
            href="{{ dataItem.link | translate }}">
            {{ dataItem.text | translate }}
        </a>
    </ng-template>
</kendo-listview>
