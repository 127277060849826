<app-content-header [actionBar]="actionBar"></app-content-header>
<app-active-filters [listOfActiveFilters]="listOfActiveFilters" (clearFilter)="clearFilter($event)" />
<app-drawer [toggle]="filterOpen" (search)="search()" (clearFilter)="clearFilter()" />
<app-grid
    selectBy="containerId"
    [data]="query()"
    [cols]="columns"
    [sort]="gridSort"
    [nrOfActiveFilters]="nrOfActiveFilters"
    [parentRoute]="parentRoute"
    [defaultViewMode]="defaultViewMode"
    [queryData]="search"
    [autoFitColumns]="true"
    [selectable]="true"
    [buttons]="gridActions"
    (saveGridSettings)="saveGridSettings($event)"
    (deleteGridSettings)="deleteGridSettings()"
    (toggleFilter)="filterDialog(filterProperties)"
    (clearFilter)="clearFilter($event)"
    (selectedKeysChange)="onSelectionChange($event)"></app-grid>
