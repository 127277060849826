import { CommonModule as AngularCommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeSi from '@angular/common/locales/en-SI';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterModule,
    provideRouter,
    withComponentInputBinding
} from '@angular/router';
import { NotificationBarComponent } from '@common/components/notification-bar.component';
import { DialogErrorHandler } from '@common/handlers/dialog-error-handler';
import { AppHttpInterceptor } from '@common/interceptors/http-interceptor';
import { provideQueryDevTools } from '@ngneat/query-devtools';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IntlModule } from '@progress/kendo-angular-intl';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routing';
import { CommonModule } from './common/common.module';
import { UserService } from './common/services/user.service';
import { HomeComponent } from './home/home.component';

registerLocaleData(localeSi, 'en-SI');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent, HomeComponent],
    exports: [BrowserAnimationsModule, IntlModule, LoadingBarModule, RouterModule, ToastrModule],
    bootstrap: [AppComponent],
    imports: [
        AngularCommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        IntlModule,
        LoadingBarModule,
        NotificationBarComponent,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        provideRouter(ROUTES, withComponentInputBinding()),
        { provide: LOCALE_ID, useValue: 'en-SI' },
        UserService,
        { provide: ErrorHandler, useClass: DialogErrorHandler },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true
        },
        environment.name === 'local'
            ? provideQueryDevTools({
                  buttonPosition: 'bottom-right'
              })
            : [],
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {
    constructor(
        router: Router,
        private loadingBarService: LoadingBarService,
        translate: TranslateService
    ) {
        const routerSate = this.loadingBarService.useRef('router');
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                routerSate.start();
            } else if (event instanceof NavigationError) {
                routerSate.complete();
                console.error(event.error);
            } else if (event instanceof NavigationEnd) {
                routerSate.complete();
            } else if (event instanceof NavigationCancel) {
                routerSate.complete();
            }
        });

        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use(environment.defaultLanguage);
    }
}
