<app-content-header
    title="{{ 'Container' | translate }} - {{ model.containerId }}"
    [actionBar]="actionBar"
    [currentNavItem]="currentNavItem"
    [breadcrumbs]="breadcrumbs"></app-content-header>

@if (!query$.isLoading) {
    <!-- TODO: codelist requests error until we go to edit mode. -->
    <div class="grid grid-cols-4 gap-4 rounded-md bg-card p-4 shadow-md">
        <!-- Container Info -->
        <app-text-input
            class="col-span-3"
            id="iso"
            [label]="'Iso' | marker"
            [isEditMode]="editMode"
            [(value)]="model.iso" />

        <app-codelist-input
            class="col-span-3"
            id="bookingType"
            codelist="BookingType"
            [label]="'Booking Type' | marker"
            [formatLabel]="codeListFormatLabel"
            [isEditMode]="editMode"
            [(value)]="model.bookingTypeId" />

        <app-codelist-input
            class="col-span-3"
            id="containerStatus"
            codelist="ContainerStatus"
            [label]="'Status' | marker"
            [formatLabel]="codeListFormatLabel"
            [isEditMode]="editMode"
            [(value)]="model.containerStatusId" />

        <!-- Container Specifications -->
        <app-codelist-input
            class="col-span-3"
            id="containerLength"
            codelist="ContainerLength"
            [label]="'Length' | marker"
            [formatLabel]="codeListFormatLabel"
            [isEditMode]="editMode"
            [(value)]="model.containerLengthId" />

        <app-codelist-input
            class="col-span-3"
            id="containerHeight"
            codelist="ContainerHeight"
            [label]="'Height' | marker"
            [formatLabel]="codeListFormatLabel"
            [isEditMode]="editMode"
            [(value)]="model.containerHeightId" />

        <app-codelist-input
            class="col-span-3"
            id="containerType"
            codelist="ContainerType"
            [label]="'Type' | marker"
            [formatLabel]="codeListFormatLabel"
            [isEditMode]="editMode"
            [(value)]="model.containerTypeId" />

        <app-codelist-input
            class="col-span-3"
            id="emptyFull"
            codelist="EmptyFull"
            [label]="'Empty Full' | marker"
            [isEditMode]="editMode"
            [formatLabel]="codeListFormatLabel"
            [(value)]="model.emptyFullId" />

        <app-text-input
            class="col-span-3"
            id="owner"
            [label]="'Owner' | marker"
            [isEditMode]="editMode"
            [(value)]="model.owner" />

        <!-- Booking Details -->
        <app-text-input
            class="col-span-3"
            id="bookingId"
            [label]="'Container Booking' | marker"
            [isEditMode]="editMode"
            [(value)]="model.bookingId" />

        <app-text-input
            class="col-span-3"
            id="serviceRequest"
            [label]="'Service Request' | marker"
            [isEditMode]="editMode"
            [(value)]="model.serviceRequest" />

        <app-codelist-input
            class="col-span-3"
            id="haulier"
            codelist="Organization"
            [label]="'Organization' | marker"
            [isEditMode]="editMode"
            [formatLabel]="codeListFormatLabel"
            [(value)]="model.haulierId" />

        <!-- Additional Info -->
        <app-codelist-input
            class="col-span-3"
            id="forwarder"
            codelist="Organization"
            [label]="'Forwarder' | marker"
            [isEditMode]="editMode"
            [formatLabel]="codeListFormatLabel"
            [(value)]="model.forwarderId" />

        <app-text-input
            class="col-span-3"
            id="imdgclass"
            [label]="'IMDG Class' | marker"
            [isEditMode]="editMode"
            [(value)]="model.imdgclass" />

        <app-number-input
            class="col-span-3"
            [label]="'Temperature' | marker"
            [isEditMode]="editMode"
            [(value)]="model.temperature" />

        <app-text-input
            class="col-span-3"
            id="bookingTos"
            [label]="'Booking Number' | marker"
            [isEditMode]="editMode"
            [(value)]="model.bookingNumber" />

        <!-- Location and Status -->
        <app-text-input
            class="col-span-3"
            id="location"
            [label]="'Location' | marker"
            [isEditMode]="editMode"
            [(value)]="model.location" />

        <!-- TODO: With hoover display a tooltip showing DamageCode - Name -->
        <app-codelist-input
            class="col-span-3"
            id="damage"
            codelist="Damage"
            [label]="'Damage' | marker"
            [isEditMode]="editMode"
            [isMultiple]="true"
            [(value)]="model.damage" />

        <!-- TODO: Id ErrorMessage != NULL, display the error message in the new field -->
        <app-text-input
            class="col-span-3"
            id="error"
            [label]="'Error' | marker"
            [isEditMode]="editMode"
            [(value)]="model.error" />
    </div>
} @else {
    <app-loader />
}
