import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ZktLoginService } from '@common/services/zkt-login.service';

export const zktRedirectLoginGuard: CanActivateFn = (route, state) => {
    const zktService = inject(ZktLoginService);
    if (zktService.get()) {
        return inject(Router).createUrlTree(['/login/zkt']);
    }
    return true;
};
