/**
 * Equipment ready status codes
 */
export enum EquipmentReadyCodes {
    /** Fully ready */
    Ready = 'ready',
    /** Not ready, but can be announced */
    Announceable = 'announceable',
    /** Cannot be announced */
    Blocked = 'blocked'
}

/**
 * Base container model interface
 */
export interface ContainerBaseModel {
    /** Container entity ID in our database - unique. */
    id: number;
    /** ContainerMove */
    eir: number | null;
    bookingTypeId: string;
    eqReady: EquipmentReadyCodes;
    containerId: string;
    containerStatusId: string;
    bookingId: number;
    bookingPin: string;
    bookingTimeFrom: Date;
    bookingTimeTo: Date;
    haulierId: string;
    /** Service Request (SRV number) */
    serviceRequest: number;
    containerHeightId: string;
    containerLengthId: string;
    containerTypeId: string;
    owner: string;
    emptyFullId: string;
    imdgclass: string;
    temperature: number;
    damageIds: string[];
    customsAllowed: boolean;
    tosAllowed: boolean;
    location: string;
    errorMessage: string;
}

export interface ContainerViewModel extends ContainerBaseModel {
    forwarderId: string;
    Iso: string;
    BookingNumber: string;
}

export interface ContainerListModel extends ContainerBaseModel {}
