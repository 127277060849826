import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { GridCellContext, GridTemplateComponent } from '@common/interfaces/column-settings.interface';

export interface DateRangeInputs {
    timeFrom: Date;
    timeTo: Date;
}

@Component({
    standalone: true,
    imports: [DatePipe],
    template: `
        <span>{{ timeFrom | date: 'MM/dd/yyy hh:mmaa' }} - {{ timeTo | date: 'hh:mmaa' }}</span>
    `
})
export class CellDateFromToComponent implements GridTemplateComponent, DateRangeInputs {
    @Input() context!: GridCellContext;
    @Input() timeFrom!: Date;
    @Input() timeTo!: Date;
}
