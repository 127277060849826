import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { ViewMode } from '@common/models/view-mode';
import { ContainerListComponent } from './container-list/container-list.component';
import { ContainerViewComponent } from './container-view/container-view.component';

export const ROUTES: Routes = [
    { path: '', redirectTo: 'list', pathMatch: 'full' },
    {
        path: 'list',
        component: ContainerListComponent
    },
    {
        path: 'edit/:id',
        component: ContainerViewComponent,
        data: {
            mode: ViewMode.edit
        }
    },
    {
        path: 'view/:id',
        component: ContainerViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'create',
        component: ContainerViewComponent,
        data: {
            mode: ViewMode.create
        }
    }
];

@NgModule({
    declarations: [ContainerListComponent, ContainerViewComponent],
    imports: [CommonModule, RouterModule.forChild(ROUTES)]
})
export class ContainerModule {}
